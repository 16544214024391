<template>
  <div id="homepage">
    <div class="page1">
      <van-image :src="require('../assets/index/logo_top.png')" />
    </div>
    <div class="page2" data-ratio="27.5">
      <img src="../assets/index/phone_page2.png" class="phone" />
      <img src="../assets/index/logo_page2.png" class="logo" />
      <div class="text1">一键记录植物成长 植物爱好者交流社区</div>
      <img src="../assets/index/ewm_ios_page2.png" :class="{ewm_ios:true,ewm:true,ewmMax:isEwmIos} "  @mouseover="upStyle(1)" @mouseleave="reStyle(1)"/>
      <img src="../assets/index/ewm_ad_page2.png" :class="{ewm_ad:true,ewm:true,ewmMax:isEwmAd}"  @mouseover="upStyle(0)" @mouseleave="reStyle(0)"/>
      <div class="text2 text2_ios">ios端下载</div>
      <div class="text2 text2_ad">安卓端下载</div>
    </div>
    <div class="page3" data-ratio="999">
      <img src="../assets/index/zw1_page3.png" class="zw1"/>
      <img src="../assets/index/zwtext1_page3.png" class="zwtext1" />
      <div class="text1">
        植物生长全纪录，随时记录植物生长状态，记录生活美好，分享经验，让更多人看到自己培育记录。
      </div>
      <img src="../assets/index/mb1_page3.png" class="mb1" />
      <img src="../assets/index/mb2_page3.png" class="mb2" />
      <img src="../assets/index/zw2_page3.png" class="zw2" />
      <img src="../assets/index/zwtext2_page3.png" class="zwtext2" />
      <div class="text2">
        个人主页成长日记全收录，一个属于自己的植物小家，分享自己的植物世界，结交更多的同好好友。
      </div>
    </div>
    <div class="page4">
      <span>
        <a href="https://beian.miit.gov.cn" style="color: #26262a"
          >备案号：闽ICP备2021013367号-1</a
        >
      </span>
      <span>|</span>
      <span>许可证：@福建自然生长科技有限公司</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage",
  mounted() {
    this.appUrl.onlyPc(); //只允许PC端打开
    document.title = this.record_title;
  },
  data() {
    return {
      record_title: "首页",
      isEwmIos:false,
      isEwmAd:false
    };
  },
  methods: {
    upStyle(i){
      if(i){
        this.isEwmIos = true;
      }else{
        this.isEwmAd = true;
      }
    },
    reStyle(i){
      if(i){
        this.isEwmIos = false;
      }else{
        this.isEwmAd = false;
      }
    }
  }
};
</script>

<style scoped>
#homepage > .page1 {
  width: 100%;
  height: 0;
  padding-top: calc(100% * 72 / 1920);
  overflow: hidden;
  position: relative;
}
#homepage > .page1 >>> .van-image {
  width: calc(100% * 127 / 1920);
  height: calc(100% * 50 / 72);
  left: 18.75%;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
#homepage > .page2 {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background-image: url("../assets/index/bg_page2.png");
  background-size: 100% 100%;
}
#homepage > .page2[data-ratio="27.5"] {
  padding-top: 27.5%;
}
#homepage > .page2 > * {
  position: absolute;
}
#homepage > .page2 .phone {
  bottom: 0;
  width: calc(100% * 260 / 1920);
  left: calc(100% * 501 / 1920);
}
#homepage > .page2 .logo {
  width: calc(100% * 120 / 1920);
  left: calc(100% * 1065 / 1920);
  top: calc(100% * 132 / 528);
}
#homepage > .page2 .text1 {
  height: calc(100% * 25 / 528);
  left: calc(100% * 1065 / 1920);
  top: calc(100% * 179 / 528);
  font-size: 18px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}
#homepage > .page2 .ewm {
  width: calc(100% * 120 / 1920);
  top: calc(100% * 247 / 528);
}
#homepage > .page2 .ewmMax{
  width: calc(160% * 120 / 1920);
  z-index: 10;
}
#homepage > .page2 .ewm_ios {
  left: calc(100% * 1065 / 1920);
}
#homepage > .page2 .ewm_ad {
  left: calc(100% * 1250 / 1920);
}
#homepage > .page2 .text2 {
  width: calc(100% * 120 / 1920);
  top: calc(100% * 385 / 528);
  text-align: center;
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}
#homepage > .page2 .text2_ios {
  left: calc(100% * 1065 / 1920);
}
#homepage > .page2 .text2_ad {
  left: calc(100% * 1250 / 1920);
}
#homepage > .page3 {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
}
#homepage > .page3[data-ratio="999"] {
  padding-top: calc(100% * 999 / 1920);
}
#homepage > .page3 > * {
  position: absolute;
}
#homepage > .page3 .zw1 {
  left: calc(100% * 482 / 1920);
  top: calc(100% * 133 / 999);
  width: calc(100% * 80 / 1920);
}
#homepage > .page3 .zwtext1 {
  left: calc(100% * 482 / 1920);
  top: calc(100% * 240 / 999);
  width: calc(100% * 214 / 1920);
}
#homepage > .page3 .text1 {
  left: calc(100% * 482 / 1920);
  top: calc(100% * 303 / 999);
  width: calc(100% * 416 / 1920);
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #26262a;
  line-height: 22px;
}
#homepage > .page3 .mb1 {
  left: calc(100% * 1090 / 1920);
  top: calc(100% * 59 / 999);
  width: calc(100% * 400 / 1920);
}
#homepage > .page3 .mb2 {
  left: calc(100% * 430 / 1920);
  /* top: calc(100% * 559 / 999); */
  bottom: 0;
  width: calc(100% * 400 / 1920);
}
#homepage > .page3 .zw2 {
  left: calc(100% * 961 / 1920);
  top: calc(100% * 646 / 999);
  width: calc(100% * 71 / 1920);
}
#homepage > .page3 .zwtext2 {
  left: calc(100% * 961 / 1920);
  top: calc(100% * 760 / 999);
  width: calc(100% * 142 / 1920);
}
#homepage > .page3 .text2 {
  left: calc(100% * 962 / 1920);
  top: calc(100% * 823 / 999);
  width: calc(100% * 416 / 1920);
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #26262a;
  line-height: 22px;
}
#homepage > .page4 {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px #f5f5f5;
  line-height: 80px;
  text-align: center;
}
#homepage > .page4 span {
  height: 14px;
  font-size: 10px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #26262a;
  line-height: 14px;
}
#homepage > .page4 span:nth-child(2) {
  padding: 0 15px;
}
</style>